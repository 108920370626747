/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import IllnessJPG from '@assets/images/illness.jpg';
import IllnessMobileJPG from '@assets/images/illness-mobile.jpg';
import { ContainerSmall } from '~/utils/styles/utils';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';
import { useTranslationContext } from '~/pages/gehirnerschuetterung-schaedel-hirn-trauma';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/traumatic-brain-injury';

const ConcussionPage: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={IllnessJPG}
        mobileHero={IllnessMobileJPG}
        altHero="Modell eines Gehirns zur Krankheitserklärung."
        enLink="/en/traumatic-brain-injury/"
        deLink="/gehirnerschuetterung-schaedel-hirn-trauma/"
      >
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('TraumaContent1') || tEn('TraumaContent1'),
            }}
          />
          <List>
            <div
              dangerouslySetInnerHTML={{
                __html: t('TraumaContent2') || tEn('TraumaContent2'),
              }}
            />
          </List>
          <div
            dangerouslySetInnerHTML={{
              __html: t('TraumaContent3') || tEn('TraumaContent3'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('TraumaContent4') || tEn('TraumaContent4'),
            }}
          />
        </ContainerSmall>
      </HeaderFooterLayout>
    </>
  );
};

const List = styled.div`
  font-size: var(--primary-font-size);
  line-height: 1.5;

  ul {
    list-style: none;
    text-indent: -0.8rem;
    padding-left: 3rem;
  }

  li::before {
    content: '- ';
    padding-right: 0.3rem;
  }
`;

export default ConcussionPage;
